export function allyNav()
{
   
    
    
    (function ($) {   
        $(document).ready(function() {


            // Remove no-js class
            $('html').removeClass('no-js');
            
            
            
            // Menu Button
            $('.menu-btn-toggle').on('click', function() {

                if ( $(this).hasClass('open') ) {

                    
                    $(this).parent().removeClass('toggled');
                    
                    /*$(this).parents('.site-header__nav').removeClass('menu-toggled');*/
                    
                    $(this).removeClass('open');

                    $(this).attr('aria-expanded', false);

                } else {

                    
                    
                    $(this).parent().addClass('toggled');
                    
                    /*$(this).parents('.site-header__nav').addClass('menu-toggled');*/
                    
                    $(this).addClass('open');

                    $(this).attr('aria-expanded', true);

                }
 
            });
            
            // Search Button
            
            $('.search-btn-toggle').on('click', function() {

                if ( $(this).hasClass('open') ) {

                    
                    $('.site-header__search').removeClass('toggled');
                    
                    $('.site-header__search input[type=search]').blur();
                    
                    $(this).removeClass('open');

                    $(this).attr('aria-expanded', false);

                } else {

                    
                    $('.site-header__search').addClass('toggled');
                    
                    $('.site-header__search input[type=search]').focus();
                    
                    $(this).addClass('open');

                    $(this).attr('aria-expanded', true);

                }
 
            });
            


            // sub menu
            // ------------------------
            
            $( '.menu-item-has-children a' ).each(function( i ) {
                
                var linkText = $(this).text();
                var btn = '<button><span><span class="sr-only">show submenu for "' + linkText + '"</span></span></button>';
                $(btn).insertAfter( $(this) );
                
            });
            
             $('.menu-item-has-children').on('touchstart mouseover', function(e) {
               
                if (event.type == "mouseover") { 
                    $(this).off('touchstart'); 
                    $(this).addClass('open'); 
                } else if (event.type == "touchstart" ) {
                    $(this).off('mouseover');     
                }
                 
                 
             });
            $('.menu-item-has-children').on('touchstart mouseout', function(e) {
                if (event.type == "mouseout") { 
                    $(this).off('touchstart'); 
                    $(this).removeClass('open'); 
                } else if (event.type == "touchstart" ) {
                    $(this).off('mouseout');
                }
                
                 
                 
             });
            $('.menu-item-has-children button').on('click', function(e) {
               
                 $(this).parent().toggleClass('open');
                 
             });
            /*$('.menu-item-has-children').on('focusout', function(e) {
               
                 $(this).removeClass('open');
                 
                 
             });*/
            
            
            
            
            
            
            
            
            
            /*if (!("ontouchstart" in document.documentElement)) {
               document.documentElement.className += " no-touch";
            }

            if (!$('html').hasClass('no-touch')) { //Execute code only on a touch screen device


                    $(document).ready(function() {
                        $('.menu-item-has-children').addClass('close');
                        $('.menu-item-has-children > a').click( function(e) {
                            $(this).parent().toggleClass('open').toggleClass('close'); 
                            //return false;
                            //alert('clicked');
                            e.preventDefault();
                        });
                    });

            }*/
            
            /*var menuItems = document.querySelectorAll('li.menu-item-has-children');
            var timer1, timer2;
            Array.prototype.forEach.call(menuItems, function(el, i){
                var activatingA = el.querySelector('a');
                var btn = '<button><span><span class="sr-only">show submenu for "' + activatingA.text + '"</span></span></button>';
                activatingA.insertAdjacentHTML('afterend', btn);
                
                el.addEventListener('mouseover', function(event){
                    var elClass = el.className;
                        this.classList.add('open');
                        this.querySelector('a').setAttribute('aria-expanded', 'true');
                        this.querySelector('button').setAttribute('aria-expanded', 'true');
                        clearTimeout(timer1);
                });
                el.addEventListener('mouseout', function(event){
                    timer1 = setTimeout(function(event){
                            document.querySelector('.menu-item-has-children.open').classList.remove ('open');
                            document.querySelector('.menu-item-has-children.open a').setAttribute('aria-expanded', 'false');
                            document.querySelector('.menu-item-has-children.open button').setAttribute('aria-expanded', 'false');
                    }, 1000);
                });
                el.querySelector('button').addEventListener("click",  function(event){
                    if (this.parentNode.className == "menu-item-has-children") {
                        this.parentNode.classList.add('open');
                        this.parentNode.querySelector('a').setAttribute('aria-expanded', "true");
                        this.parentNode.querySelector('button').setAttribute('aria-expanded', "true");
                    } else {
                        this.parentNode.classList.remove('open');
                        this.parentNode.querySelector('a').setAttribute('aria-expanded', "false");
                        this.parentNode.querySelector('button').setAttribute('aria-expanded', "false");
                    }
                    event.preventDefault();
                });
                var links = el.querySelectorAll('a');
                Array.prototype.forEach.call(links, function(el, i){
                    el.addEventListener("focus", function() {
                        if (timer2) {
                            clearTimeout(timer2);
                            timer2 = null;
                        }
                    });
                    el.addEventListener("blur", function(event) {
                        timer2 = setTimeout(function () {
                            var opennav = document.querySelector(".menu-item-has-children.open")
                            if (opennav) {
                                opennav.classList.remove('open');
                                opennav.querySelector('a').setAttribute('aria-expanded', "false");
                                opennav.querySelector('button').setAttribute('aria-expanded', "false");
                            }
                        }, 10);
                    });
                });
            
            });*/
            
                                    

        });
        
        //---------- Test for browser width ----------------
        
        // Define our viewportWidth variable
        var viewportWidth;

        // Set/update the viewportWidth value
        var setViewportWidth = function () {
            viewportWidth = window.innerWidth || document.documentElement.clientWidth;
        }

        // Log the viewport width into the console
        var doWhenWidth = function () {
            if (viewportWidth > 768) {
                
                $(document).mouseup(function (e){

                    var container = $(".site-header__search, .search-btn-toggle");

                    if (!container.is(e.target) && container.has(e.target).length === 0){

                        container.removeClass('toggled').find('input[type=search]').blur();

                        $('.search-btn-toggle').removeClass('open').attr('aria-expanded', false);

                    }
                }); 
                
            }
        }

        // Set our initial width and log it
        setViewportWidth();
        doWhenWidth();

        // On resize events, recalculate and log
        window.addEventListener('resize', function () {
            setViewportWidth();
            doWhenWidth();
        }, false);
        
        // Detect when user clicks outside of the opened element
        
        
        
         // Test if dropdown is offscreen and add class so that we can style it to ensure dropdown is still visible
        $.extend($.expr[':'], {
            'off-top': function(el) {
              return $(el).offset().top < $(window).scrollTop();
            },
            'off-right': function(el) {
              return $(el).offset().left + $(el).outerWidth() - $(window).scrollLeft() > $(window).width();
            },
            'off-bottom': function(el) {
              return $(el).offset().top + $(el).outerHeight() - $(window).scrollTop() > $(window).height();
            },
            'off-left': function(el) {
              return $(el).offset().left < $(window).scrollLeft();
            },
            'off-screen': function(el) {
              return $(el).is(':off-top, :off-right, :off-bottom, :off-left');
            }
             /* USAGE
             $('#element').is(':off-top')    // top of the viewport
            $('#element').is(':off-right')  // right-side of the viewport
            $('#element').is(':off-bottom') // bottom of the viewport
            $('#element').is(':off-left')   // left-side of the viewport
            $('#element').is(':off-screen') // any side of the viewport
            */
        });
        
        $(document).ready(function() {
        
             $('.menu-item-has-children > a').on('hover click focus', function() {
                 var dropdown = $(this),
                     menu = dropdown.next('.sub-menu');
                 // Restore to default position
                 menu.removeClass('sub-menu--right');
                 // Adjust if it's off the screen
                 if( menu.is(':off-right') ) {
                    menu.addClass('sub-menu--right');
                }
             });
            
        });
         
        
         
         
     })(jQuery); 
    
    
   
  
   
}