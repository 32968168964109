
import { allyNav } from "./nav.js";

import { objectFit } from "./object-fit-polyfill.js"; // quiet

import { fancybox } from "./jquery.fancybox.js"; // quiet

import { owlCarousel } from "./owl.carousel.js"; // quiet

allyNav();
objectFit();
fancybox();
owlCarousel();

(function () {
    
     
    jQuery(document).ready(function() {
        jQuery('.owl-carousel').owlCarousel({
            loop: true,
            dots: false,
            autoplay: true,
            autoplayHoverPause: true,
            responsive : {
                // breakpoint from 0 up
                0 : {
                    items: 3,
                    margin: 35,
                },
                // breakpoint from 480 up
                600 : {
                    items: 4,
                    margin: 45,
                },
                // breakpoint from 768 up
                900 : {
                    items: 5,
                    margin: 75,
                }
            }
        });
    });
    
})(jQuery);

